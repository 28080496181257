import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { SidenavService } from '../services/sidenav.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { PasswordStrengthValidator } from "../password-strength.validators";
import { UsersService } from '../services/users.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ViewChild('changePwd', { static: true }) public privacyPopup: TemplateRef<any>;
  modalRef: BsModalRef;
  userDetails: any;
  walletBalance: number = 0;
  exposure: number = 0;
  casinoBal: number;
  hide: boolean = true;
  moment: any = moment;
  chngePass: number;
  submitted = false;
  tokenStatus: boolean = false;
  avaialableEventType: any;
  rollingBalance: any;
  ListData: any;
  offerstatment:any;
  myForms: FormGroup;
  selected: any = false;
  promo:any;
  aviatorArr: any;
  aviatorlog: any;
  ballArr: any;
  constructor(private router: Router,  public httpClient: UsersService, private sidenav: SidenavService, private toastr: ToastrService, private socket: Socket, private sidenavService: SidenavService, private modalService: BsModalService, private fb: FormBuilder) { 

  }

  ngOnInit(): void {
    if (sessionStorage.getItem('loginStatus') === "true") {
      this.getUserBalance();
      this.settings();
      this.getFixedDeposit();
      this.tokenStatus = true;
      this.createpassword();
      this.geteferacc();
   this.promo = localStorage.getItem('props');
    }
  }
  async geteferacc() {
    this.userDetails = await this.getDetials();
    this.getRefAcc();
  }
  
  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }

  }

  async getUserBalance() {
    this.userDetails = await this.getDetials();
    // console.log(this.userDetails.details.manager);   
    this.avaialableEventType = this.userDetails?.details?.availableEventTypes.indexOf("8080");
    //  console.log(this.avaialableEventType);   
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    const offerdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username:this.userDetails.details.username,
        visible:true} 
    };
    // console.log(offerdata);
    this.socket.emit('get-user', userdata);
    // this.socket.emit('offer-statement', offerdata);

    this.socket.on('get-user-success', (function (data: any) {
      if (data) {
        this.walletBalance = data.balance;
        this.rollingBalance = data;
        // console.log(data);
        this.exposure = data.exposure;
        this.getCasinoBal();
      }
    }).bind(this));

    // this.socket.on('offer-statement-success', (function (data: any) {
    //   if (data) {
    //     this.offerstatment = data.data;
    //     console.log(this.offerstatment);
    //   }
    // }).bind(this));

  }

  createpassword() {
    this.myForms = this.fb.group({
      password: [null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])]
    });
  }

  passwordchange() {
    //  console.log(this.myForms.value);

  }
  async settings(){
    this.userDetails = await this.getDetials();
    var data = {user_id:this.userDetails?.details?._id}
    //console.log(this.userDetails);
    this.httpClient.SocketPost("setting",data).subscribe((res: any) => {
      this.ballArr = res?.user?.availableEventTypes;
    //  console.log(this.ballArr);
      if(this.ballArr && Array.isArray(this.ballArr) && this.ballArr.includes('aviator')){
        this.aviatorlog='true';
      //  console.log("aviator");
      }
     // console.log(this.aviatorlog);
    });
  }
  onSubmitChangePassword() {
    this.submitted = true;

    if (this.myForms.value.password) {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },

        },
        password: this.myForms.value.password,
        targetUser: '',
      };

      this.socket.emit('update-password', userdata);

      this.socket.on('update-password-success', (function (data: any) {

        if (data) {
          this.submitted = false;
          this.modalRef.hide();
          this.toastr.success(data.message, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          setTimeout(() => { this.logoutUser(); }, 1000);
        }
      }).bind(this));
    }
    else {
      this.toastr.error('new password is req');
    }

  }


  getCasinoBal() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('get-userbalance', userdata);

    this.socket.on('get-balance-success', (function (data: any) {

      if (data) {
        this.casinoBal = data.amount;
        // console.log(data);
      }
    }).bind(this));

  }

  dashboard() {
    this.router.navigate(['dashboard'])
  }

  inplay() {
    this.router.navigate(['inplay'])
  }

  toggleRightSidenav() {
    this.sidenav.toggle();
  }

  openModalResetPwd(changePwd: TemplateRef<any>) {
    if (this.tokenStatus) {
      this.modalRef = this.modalService.show(
        changePwd,
        Object.assign({}, { class: 'changePwd-modal modal-lg', ignoreBackdropClick: true })
      );
    } else {
      this.router.navigate(['login']);
    }

  }

  clipBoard(val: any) {
    if (this.selected) {
      this.selected = false;
    }
    else {
      this.selected = true;
    }

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  coptext(){
    if (this.selected) {
      this.selected = false;
    }
    else {
      this.selected = true;
    }
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "Hey Friend, look what i have for you found the best sports and casino betting site and i am introducing the same to you Its called telegram.com. Signup now using my refer code" + " "  + this.promo +" "+ "and you get 1500 welcome bonus in your bonus wallet. I got my first withdrawal in less than 2 minutes, why don’t you give it a try!";
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox); 
  }

  getRefAcc() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      sort: { time: -1 },
    };

    this.socket.emit('get-referal-userList', userdata);

    this.socket.on('get-referals-user-success', (function (data: any) {
    //  console.log(data.promoCode);
      localStorage.setItem('props', data.promoCode);
    }).bind(this));

  }
  set_fantacy_model() {
    localStorage.setItem('fantacy_model', 'true');
  }

  async getFixedDeposit() {
    this.userDetails = await this.getDetials();
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          manager: this.userDetails.details.manager,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    // console.log(userdata);

    this.socket.emit('get-fixed-deposit-list', userdata);

    this.socket.on('get-fixed-deposit-list-success', (function (data: any) {
      // console.log(data)
      if (data) {
        this.ListData = data?.data;
        // console.log(this.ListData);

      }
    }).bind(this));

  }

  logoutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
    window.location.replace('login');
  }


}
